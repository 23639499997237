define("spaces-view/lib/known-query-parameters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.knownQueryParameters = void 0;
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  const knownQueryParameters = _exports.knownQueryParameters = {
    feature: {
      map: {
        type: 'RecordId',
        model: 'map'
      },
      icons: {
        type: 'IdList',
        model: 'icon'
      },
      limit: {
        type: 'number'
      },
      sortBy: {
        type: 'text'
      },
      sortOrder: {
        type: 'text'
      },
      attributes: {
        type: 'text'
      }
    },
    event: {
      calendar: {
        type: 'RecordId',
        model: 'calendar'
      },
      limit: {
        type: 'number'
      },
      location: {
        type: 'RecordId',
        model: 'feature'
      },
      date: {
        type: 'text'
      },
      attributes: {
        type: 'text'
      },
      repetition: {
        type: 'text'
      },
      dayOfWeek: {
        type: 'text'
      },
      icons: {
        type: 'IdList',
        model: 'icon'
      },
      onlyFuture: {
        type: 'text'
      },
      onlyPast: {
        type: 'text'
      }
    },
    campaign: {
      team: {
        type: 'RecordId',
        model: 'team'
      },
      map: {
        type: 'RecordId',
        model: 'map'
      },
      limit: {
        type: 'number'
      },
      term: {
        type: 'string'
      }
    },
    map: {
      team: {
        type: 'RecordId',
        model: 'team'
      },
      limit: {
        type: 'number'
      }
    },
    'icon-set': {
      team: {
        type: 'RecordId',
        model: 'team'
      }
    },
    icon: {
      map: {
        type: 'RecordId',
        model: 'map'
      },
      iconSet: {
        type: 'RecordId',
        model: 'icon-set'
      },
      category: {
        type: 'text'
      },
      subcategory: {
        type: 'text'
      }
    },
    article: {
      category: {
        type: 'text'
      },
      sortBy: {
        type: 'options',
        values: ['auto', 'order', 'title', 'releaseDate']
      },
      sortOrder: {
        type: 'options',
        values: ['auto', 'asc', 'desc']
      },
      limit: {
        type: 'number'
      }
    }
  };
});