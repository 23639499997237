define("spaces-view/components/state-filters/name", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "@ember/object", "core/lib/page-state", "@glimmer/tracking", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _component2, _service, _object, _pageState, _tracking, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="state-filters-name" ...attributes>
    <TextWithOptions @options={{@options.label}} @deviceSize={{@deviceSize}} @tag="label" data-name="filter label"
      data-type="label" data-path="{{@path}}.label" data-gid={{@colValue.gid}} class="form-label" for={{this.inputId}}>{{!--
      --}}{{t "search-by-name"}}{{!--
    --}}</TextWithOptions>
  
    <div class="input-group mb-3">
      {{!-- template-lint-disable builtin-component-arguments --}}
      {{!-- template-lint-disable no-builtin-form-components --}}
      {{!-- template-lint-disable require-input-label --}}
      <Input type="text" class="form-control input-name-search" placeholder={{t "type here to search by name"}} @value={{this.term}} />
  
      {{#if this.term}}
        <button class="btn btn-outline-secondary btn-clear" type="button" {{on "click" (fn this.change "")}}>
          <FaIcon @icon="xmark" />
        </button>
      {{/if}}
    </div>
  </div>
  */
  {
    "id": "Cfox6BSp",
    "block": "[[[11,0],[24,0,\"state-filters-name\"],[17,1],[12],[1,\"\\n  \"],[8,[39,1],[[24,\"data-name\",\"filter label\"],[24,\"data-type\",\"label\"],[16,\"data-path\",[29,[[30,2],\".label\"]]],[16,\"data-gid\",[30,3,[\"gid\"]]],[24,0,\"form-label\"],[16,\"for\",[30,0,[\"inputId\"]]]],[[\"@options\",\"@deviceSize\",\"@tag\"],[[30,4,[\"label\"]],[30,5],\"label\"]],[[\"default\"],[[[[1,[28,[35,2],[\"search-by-name\"],null]]],[]]]]],[1,\"\\n\\n  \"],[10,0],[14,0,\"input-group mb-3\"],[12],[1,\"\\n\"],[1,\"    \"],[8,[39,3],[[24,0,\"form-control input-name-search\"],[16,\"placeholder\",[28,[37,2],[\"type here to search by name\"],null]],[24,4,\"text\"]],[[\"@value\"],[[30,0,[\"term\"]]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"term\"]],[[[1,\"      \"],[11,\"button\"],[24,0,\"btn btn-outline-secondary btn-clear\"],[24,4,\"button\"],[4,[38,6],[\"click\",[28,[37,7],[[30,0,[\"change\"]],\"\"],null]],null],[12],[1,\"\\n        \"],[8,[39,8],null,[[\"@icon\"],[\"xmark\"]],null],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@path\",\"@colValue\",\"@options\",\"@deviceSize\"],false,[\"div\",\"text-with-options\",\"t\",\"input\",\"if\",\"button\",\"on\",\"fn\",\"fa-icon\"]]",
    "moduleName": "spaces-view/components/state-filters/name.hbs",
    "isStrictMode": false
  });
  let StateFiltersName = _exports.default = (_class = class StateFiltersName extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "intl", _descriptor, this);
      _initializerDefineProperty(this, "_term", _descriptor2, this);
    }
    get term() {
      if (typeof this._term == 'string') {
        return this._term;
      }
      return this.state.n;
    }
    set term(value) {
      this.change(value);
    }
    get state() {
      return (0, _pageState.fromPageState)(this.args.state);
    }
    updateValue() {
      let n = this.term;
      if (!n) {
        n = undefined;
      }
      const newState = (0, _pageState.toPageState)({
        ...this.state,
        n
      });
      this.args.onChangeState?.(newState);
    }
    change(value) {
      this._term = value;
      (0, _runloop.debounce)(this, this.updateValue, 1000);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "_term", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, StateFiltersName);
});