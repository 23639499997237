define("spaces/components/editor/attributes-group", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::Frame class="text-list" @title={{capitalize (t "option-panel.attributes-list")}}>
    <Input::StringList @value={{this.groups}} @onChange={{fn this.change "groups"}} />
  </Input::Options::Frame>
  */
  {
    "id": "AfHXcYxY",
    "block": "[[[8,[39,0],[[24,0,\"text-list\"]],[[\"@title\"],[[28,[37,1],[[28,[37,2],[\"option-panel.attributes-list\"],null]],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,[[\"@value\",\"@onChange\"],[[30,0,[\"groups\"]],[28,[37,4],[[30,0,[\"change\"]],\"groups\"],null]]],null],[1,\"\\n\"]],[]]]]]],[],false,[\"input/options/frame\",\"capitalize\",\"t\",\"input/string-list\",\"fn\"]]",
    "moduleName": "spaces/components/editor/attributes-group.hbs",
    "isStrictMode": false
  });
  let EditorAttributesGroup = _exports.default = (_class = class EditorAttributesGroup extends _component2.default {
    get groups() {
      return this.args.value?.groups ?? [];
    }
    change(name, value) {
      this.args.onChange?.({
        [name]: value
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "change", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "change"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorAttributesGroup);
});