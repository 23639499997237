define("spaces/components/editor/menu-components", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::Panel @space={{@space}} @config={{this.panel}} @value={{@value}} @onChange={{@onChange}} />
  */
  {
    "id": "8tmEWlyd",
    "block": "[[[8,[39,0],null,[[\"@space\",\"@config\",\"@value\",\"@onChange\"],[[30,1],[30,0,[\"panel\"]],[30,2],[30,3]]],null]],[\"@space\",\"@value\",\"@onChange\"],false,[\"input/options/panel\"]]",
    "moduleName": "spaces/components/editor/menu-components.hbs",
    "isStrictMode": false
  });
  class EditorMenuComponents extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "panel", [{
        name: "showLogo",
        type: "bool",
        label: "show logo"
      }, {
        name: "showLogin",
        type: "bool",
        label: "show login"
      }, {
        name: "showSearch",
        type: "bool",
        label: "show search"
      }]);
    }
  }
  _exports.default = EditorMenuComponents;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorMenuComponents);
});