define("spaces/components/editor/icon-container", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Input::Options::Frame @title={{t "option-panel.icon-container"}} ...attributes>
    <Input::Options::Panel @config={{this.config}} @space={{@space}} @space={{@space}} @deviceSize={{@deviceSize}} @value={{@value}} @onChange={{@onChange}} />
  </Input::Options::Frame>
  */
  {
    "id": "V3b6hN8X",
    "block": "[[[8,[39,0],[[17,1]],[[\"@title\"],[[28,[37,1],[\"option-panel.icon-container\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@config\",\"@space\",\"@space\",\"@deviceSize\",\"@value\",\"@onChange\"],[[30,0,[\"config\"]],[30,2],[30,2],[30,3],[30,4],[30,5]]],null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"@space\",\"@deviceSize\",\"@value\",\"@onChange\"],false,[\"input/options/frame\",\"t\",\"input/options/panel\"]]",
    "moduleName": "spaces/components/editor/icon-container.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class EditorIconContainerComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "config", [{
        type: "px",
        name: "height"
      }, {
        type: "margin"
      }, {
        type: "link",
        name: "destination"
      }]);
    }
  }
  _exports.default = EditorIconContainerComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorIconContainerComponent);
});