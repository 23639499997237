define("spaces/components/editor/generic-list", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "models/lib/array", "@ember/template-factory"], function (_exports, _component, _component2, _object, _array, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Editor::Properties::OptionContainer @label={{t "option-panel.list-count"}} as |id|>
    <Input::Text @type="number" @value={{this.listCount}} @onChange={{this.changeListCount}} class="text-list-count form-control" id={{id}} />
  </Editor::Properties::OptionContainer>
  */
  {
    "id": "zDNlCYoh",
    "block": "[[[8,[39,0],null,[[\"@label\"],[[28,[37,1],[\"option-panel.list-count\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],[[24,0,\"text-list-count form-control\"],[16,1,[30,1]]],[[\"@type\",\"@value\",\"@onChange\"],[\"number\",[30,0,[\"listCount\"]],[30,0,[\"changeListCount\"]]]],null],[1,\"\\n\"]],[1]]]]]],[\"id\"],false,[\"editor/properties/option-container\",\"t\",\"input/text\"]]",
    "moduleName": "spaces/components/editor/generic-list.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */
  let EditorGenericListComponent = _exports.default = (_class = class EditorGenericListComponent extends _component2.default {
    get listCount() {
      return this.args.value?.length ?? 0;
    }
    changeListCount(value) {
      if (isNaN(value)) {
        return;
      }
      let result = (0, _array.A)(this.args.value);
      if (value < this.args.value?.length ?? 0) {
        result = (0, _array.A)(result.slice(0, value));
      }
      while (result.length < parseInt(value)) {
        result.push(null);
      }
      this.args.onChange(result);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "changeListCount", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeListCount"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorGenericListComponent);
});