define("spaces-view/components/view/card", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Container @value={{@value.data.container}} @deviceSize={{@deviceSize}} data-name={{@value.name}}
    data-gid={{@value.gid}} data-path="{{@path}}.data.container" class="card-view use-highlights">
  
    <View::Picture @model={{@model}} @value={{@value}} @deviceSize={{@deviceSize}} @path={{@path}} @space={{@space}} @isEditor={{@isEditor}}/>
  
    <TextWithOptions
      @deviceSize={{@deviceSize}}
      @options={{@value.data.titleStyle}}
      @tag="h"
      @isEditor={{@isEditor}}
      class="use-highlights"
      data-text-value="true"
      data-path={{if @path (concat @path ".data.titleStyle")}}
      data-name="title"
      data-gid={{@value.gid}}>{{!--
      --}}{{@value.data.titleStyle.text}}{{!--
    --}}</TextWithOptions>
  
    <TextWithOptions
      @deviceSize={{@deviceSize}}
      @options={{@value.data.style}}
      @tag="p"
      @isEditor={{@isEditor}}
      class="use-highlights"
      data-text-value="true"
      data-path={{if @path (concat @path ".data.style")}}
      data-name="paragraph"
      data-gid={{@value.gid}}>{{!--
      --}}{{@value.data.style.text}}{{!--
    --}}</TextWithOptions>
  
  
    <Button
        @value={{@value.data.button}}
        @isEditor={{@isEditor}}
        @deviceSize={{@deviceSize}}
        @model={{@model}}
        data-path="{{@path}}.data.button"
        data-name={{@value.name}}
        data-gid={{@value.gid}}
        data-editor="button"
        data-type="button"
        data-model="page"
        data-model-id={{@model.page.id}}
      />
  
  </Container>
  */
  {
    "id": "qoUqDMSB",
    "block": "[[[8,[39,0],[[16,\"data-name\",[30,1,[\"name\"]]],[16,\"data-gid\",[30,1,[\"gid\"]]],[16,\"data-path\",[29,[[30,2],\".data.container\"]]],[24,0,\"card-view use-highlights\"]],[[\"@value\",\"@deviceSize\"],[[30,1,[\"data\",\"container\"]],[30,3]]],[[\"default\"],[[[[1,\"\\n\\n  \"],[8,[39,1],null,[[\"@model\",\"@value\",\"@deviceSize\",\"@path\",\"@space\",\"@isEditor\"],[[30,4],[30,1],[30,3],[30,2],[30,5],[30,6]]],null],[1,\"\\n\\n  \"],[8,[39,2],[[24,0,\"use-highlights\"],[24,\"data-text-value\",\"true\"],[16,\"data-path\",[52,[30,2],[28,[37,4],[[30,2],\".data.titleStyle\"],null]]],[24,\"data-name\",\"title\"],[16,\"data-gid\",[30,1,[\"gid\"]]]],[[\"@deviceSize\",\"@options\",\"@tag\",\"@isEditor\"],[[30,3],[30,1,[\"data\",\"titleStyle\"]],\"h\",[30,6]]],[[\"default\"],[[[[1,[30,1,[\"data\",\"titleStyle\",\"text\"]]]],[]]]]],[1,\"\\n\\n  \"],[8,[39,2],[[24,0,\"use-highlights\"],[24,\"data-text-value\",\"true\"],[16,\"data-path\",[52,[30,2],[28,[37,4],[[30,2],\".data.style\"],null]]],[24,\"data-name\",\"paragraph\"],[16,\"data-gid\",[30,1,[\"gid\"]]]],[[\"@deviceSize\",\"@options\",\"@tag\",\"@isEditor\"],[[30,3],[30,1,[\"data\",\"style\"]],\"p\",[30,6]]],[[\"default\"],[[[[1,[30,1,[\"data\",\"style\",\"text\"]]]],[]]]]],[1,\"\\n\\n\\n  \"],[8,[39,5],[[16,\"data-path\",[29,[[30,2],\".data.button\"]]],[16,\"data-name\",[30,1,[\"name\"]]],[16,\"data-gid\",[30,1,[\"gid\"]]],[24,\"data-editor\",\"button\"],[24,\"data-type\",\"button\"],[24,\"data-model\",\"page\"],[16,\"data-model-id\",[30,4,[\"page\",\"id\"]]]],[[\"@value\",\"@isEditor\",\"@deviceSize\",\"@model\"],[[30,1,[\"data\",\"button\"]],[30,6],[30,3],[30,4]]],null],[1,\"\\n\\n\"]],[]]]]]],[\"@value\",\"@path\",\"@deviceSize\",\"@model\",\"@space\",\"@isEditor\"],false,[\"container\",\"view/picture\",\"text-with-options\",\"if\",\"concat\",\"button\"]]",
    "moduleName": "spaces-view/components/view/card.hbs",
    "isStrictMode": false
  });
  class ViewCard extends _component2.default {}
  _exports.default = ViewCard;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewCard);
});