define("spaces/components/editor/map", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    
  <Input::Options::Panel @space={{@space}} @config={{this.config}} @value={{@value}} @onChange={{@onChange}} />
  */
  {
    "id": "nm0XYr74",
    "block": "[[[1,\"\\n\"],[8,[39,0],null,[[\"@space\",\"@config\",\"@value\",\"@onChange\"],[[30,1],[30,0,[\"config\"]],[30,2],[30,3]]],null]],[\"@space\",\"@value\",\"@onChange\"],false,[\"input/options/panel\"]]",
    "moduleName": "spaces/components/editor/map.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class EditorMapComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "config", [{
        name: "mode",
        options: ["show all features", "use a map"]
      }, {
        name: "flyOverAnimation",
        type: "bool"
      }, {
        name: "featureHover",
        options: ["do nothing", "mouse pointer"]
      }, {
        name: "featureSelection",
        options: ["do nothing", "open details in panel", "show popup", "go to page"]
      }, {
        name: "enableMapInteraction",
        options: ["no", "always", "automatic"]
      }, {
        name: "enablePinning",
        type: "bool"
      }, {
        name: "restrictView",
        type: "bool"
      }, {
        name: "showUserLocation",
        type: "bool"
      }, {
        name: "showZoomButtons",
        type: "bool"
      }, {
        name: "showBaseMapSelection",
        type: "bool"
      }, {
        name: "showTitle",
        type: "bool"
      }, {
        name: "enableFullMapView",
        type: "bool"
      }]);
    }
  }
  _exports.default = EditorMapComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorMapComponent);
});