define("spaces-view/components/view/survey-pending-answers", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @isEditor}}
    <TextWithOptions @tag="div" @deviceSize={{@deviceSize}} @options={{@value.data.message}}
      data-path="{{@path}}.data.message" data-type="message" data-name={{@value.name}} data-gid={{@value.gid}}>{{!
      }}There are <strong>5</strong> pending answers.{{!
    }}</TextWithOptions>
  
    <View::ButtonStyle @value={{@value.data.button}} class="btn" data-type="submit button" data-editor="button-style"
      data-path={{concat @path ".data.button"}} data-name={{@value.name}} data-gid={{this.valueGid}}>
      {{t "send all pending answers"}}
    </View::ButtonStyle>
  {{/if}}
  */
  {
    "id": "QhAPK/v3",
    "block": "[[[41,[30,1],[[[1,\"  \"],[8,[39,1],[[16,\"data-path\",[29,[[30,2],\".data.message\"]]],[24,\"data-type\",\"message\"],[16,\"data-name\",[30,3,[\"name\"]]],[16,\"data-gid\",[30,3,[\"gid\"]]]],[[\"@tag\",\"@deviceSize\",\"@options\"],[\"div\",[30,4],[30,3,[\"data\",\"message\"]]]],[[\"default\"],[[[[1,\"There are \"],[10,\"strong\"],[12],[1,\"5\"],[13],[1,\" pending answers.\"]],[]]]]],[1,\"\\n\\n  \"],[8,[39,3],[[24,0,\"btn\"],[24,\"data-type\",\"submit button\"],[24,\"data-editor\",\"button-style\"],[16,\"data-path\",[28,[37,4],[[30,2],\".data.button\"],null]],[16,\"data-name\",[30,3,[\"name\"]]],[16,\"data-gid\",[30,0,[\"valueGid\"]]]],[[\"@value\"],[[30,3,[\"data\",\"button\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[1,[28,[35,5],[\"send all pending answers\"],null]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null]],[\"@isEditor\",\"@path\",\"@value\",\"@deviceSize\"],false,[\"if\",\"text-with-options\",\"strong\",\"view/button-style\",\"concat\",\"t\"]]",
    "moduleName": "spaces-view/components/view/survey-pending-answers.hbs",
    "isStrictMode": false
  });
  let ViewSurveyPendingAnswers = _exports.default = (_class = class ViewSurveyPendingAnswers extends _component2.default {
    updateProps() {}
  }, _applyDecoratedDescriptor(_class.prototype, "updateProps", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateProps"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ViewSurveyPendingAnswers);
});