define("spaces/components/editor/text-with-options", ["exports", "@ember/component", "@glimmer/component", "@ember/template-factory"], function (_exports, _component, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Editor::Properties::Text @space={{@space}} @value={{@value}} @onChange={{@onChange}} @isHeading={{this.isHeading}} @enableTextValue={{this.enableTextValue}} />
  */
  {
    "id": "nc56mqbl",
    "block": "[[[8,[39,0],null,[[\"@space\",\"@value\",\"@onChange\",\"@isHeading\",\"@enableTextValue\"],[[30,1],[30,2],[30,3],[30,0,[\"isHeading\"]],[30,0,[\"enableTextValue\"]]]],null]],[\"@space\",\"@value\",\"@onChange\"],false,[\"editor/properties/text\"]]",
    "moduleName": "spaces/components/editor/text-with-options.hbs",
    "isStrictMode": false
  });
  /**
    Copyright: © 2015-2024 GISCollective
    License: Subject to the terms of the AFFERO GENERAL PUBLIC LICENSE, as written in the included COPYING file.
  */

  class EditorTextWithOptionsComponent extends _component2.default {
    get isHeading() {
      if (this.args.element?.attributes?.getNamedItem?.("data-selectable-level")?.value.toLowerCase?.() == "false") {
        return false;
      }
      const tagName = this.args.element?.tagName?.toLowerCase?.();
      return ["h1", "h2", "h3", "h4", "h5", "h6"].includes(tagName);
    }
    get enableTextValue() {
      return this.args.element?.attributes?.getNamedItem?.("data-text-value")?.value.toLowerCase?.() == "true";
    }
  }
  _exports.default = EditorTextWithOptionsComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorTextWithOptionsComponent);
});