define("spaces/components/editor/properties/button-image", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <Editor::Properties::OptionContainer @label={{t "option-panel.picture"}}>
    <Input::PictureSelector @id={{this.value.picture}} @size="sm" class="input-btn-picture" @model={{@model}} @modelId={{@modelId}} @onChange={{fn this.changePicture "picture"}}/>
  </Editor::Properties::OptionContainer>
  
  <Editor::Properties::OptionContainer @label={{t "option-panel.hover-picture"}} @model={{@model}} @modelId={{@modelId}} class="input-btn-hover">
    <Input::PictureSelector @id={{this.value.hover}} @size="sm" @onChange={{fn this.changePicture "hover"}} />
  </Editor::Properties::OptionContainer>
  
  <Editor::Properties::OptionContainer @label={{t "option-panel.active-picture"}} @model={{@model}} @modelId={{@modelId}} class="input-btn-active">
    <Input::PictureSelector @id={{this.value.active}} @size="sm" @onChange={{fn this.changePicture "active"}} />
  </Editor::Properties::OptionContainer>
  
  <Input::Options::SizePx @property="height" @value={{this.value.style}} @onChange={{this.changeStyle}} />
  
  <Editor::Properties::OptionContainer @label={{t "option-panel.position"}} as | id |>
    <Input::Select class="form-select input-position" id={{id}} @list={{this.positionOptions}} @value={{this.value.position}} @onChange={{this.changePosition}} />
  </Editor::Properties::OptionContainer>
  */
  {
    "id": "Xf14OCkL",
    "block": "[[[8,[39,0],null,[[\"@label\"],[[28,[37,1],[\"option-panel.picture\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],[[24,0,\"input-btn-picture\"]],[[\"@id\",\"@size\",\"@model\",\"@modelId\",\"@onChange\"],[[30,0,[\"value\",\"picture\"]],\"sm\",[30,1],[30,2],[28,[37,3],[[30,0,[\"changePicture\"]],\"picture\"],null]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,0],[[24,0,\"input-btn-hover\"]],[[\"@label\",\"@model\",\"@modelId\"],[[28,[37,1],[\"option-panel.hover-picture\"],null],[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@id\",\"@size\",\"@onChange\"],[[30,0,[\"value\",\"hover\"]],\"sm\",[28,[37,3],[[30,0,[\"changePicture\"]],\"hover\"],null]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,0],[[24,0,\"input-btn-active\"]],[[\"@label\",\"@model\",\"@modelId\"],[[28,[37,1],[\"option-panel.active-picture\"],null],[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,2],null,[[\"@id\",\"@size\",\"@onChange\"],[[30,0,[\"value\",\"active\"]],\"sm\",[28,[37,3],[[30,0,[\"changePicture\"]],\"active\"],null]]],null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@property\",\"@value\",\"@onChange\"],[\"height\",[30,0,[\"value\",\"style\"]],[30,0,[\"changeStyle\"]]]],null],[1,\"\\n\\n\"],[8,[39,0],null,[[\"@label\"],[[28,[37,1],[\"option-panel.position\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,5],[[24,0,\"form-select input-position\"],[16,1,[30,3]]],[[\"@list\",\"@value\",\"@onChange\"],[[30,0,[\"positionOptions\"]],[30,0,[\"value\",\"position\"]],[30,0,[\"changePosition\"]]]],null],[1,\"\\n\"]],[3]]]]]],[\"@model\",\"@modelId\",\"id\"],false,[\"editor/properties/option-container\",\"t\",\"input/picture-selector\",\"fn\",\"input/options/size-px\",\"input/select\"]]",
    "moduleName": "spaces/components/editor/properties/button-image.hbs",
    "isStrictMode": false
  });
  let EditorAttributesGroup = _exports.default = (_class = class EditorAttributesGroup extends _component2.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "positionOptions", ["start", "end", "top", "bottom"]);
    }
    get value() {
      return this.args.value ?? {};
    }
    changePosition(position) {
      const newValue = {
        ...this.value,
        position
      };
      this.args.onChange?.(newValue);
    }
    changeStyle(style) {
      const newValue = {
        ...this.value,
        style
      };
      this.args.onChange?.(newValue);
    }
    async changePicture(key, value) {
      await value?.save?.();
      const newValue = {
        ...this.value,
        [key]: value?.id
      };
      return this.args.onChange?.(newValue);
    }
  }, _applyDecoratedDescriptor(_class.prototype, "changePosition", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changePosition"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeStyle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changeStyle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changePicture", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "changePicture"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, EditorAttributesGroup);
});