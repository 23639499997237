define("map/components/map-location-selector", ["exports", "@ember/component", "@glimmer/component", "@ember/service", "core/lib/geoJson", "ol/proj", "@ember/object", "@ember/template-factory"], function (_exports, _component, _component2, _service, _geoJson, _proj, _object, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <MapInteractive @followUserLocation={{this.userInExtent}} @center={{@center}} @userLocation={{this.position.center}} class="map-location-selector" as | map |>
    <div class="d-none" {{did-insert (fn this.setupExtent map)}}></div>
    <Map::MapCenterMarker @message={{t 'message-map-marker-point-move'}} />
  
    <Map::Container @right={{true}} @bottom={{true}} @left={{true}} class="p-3 text-center">
      <Input::Button class="btn btn-success btn-save btn-lg" type="button" @action={{this.handleSave map}}>{{t "save"}}</Input::Button>
    </Map::Container>
  </MapInteractive>
  
  */
  {
    "id": "XAa0mVLm",
    "block": "[[[8,[39,0],[[24,0,\"map-location-selector\"]],[[\"@followUserLocation\",\"@center\",\"@userLocation\"],[[30,0,[\"userInExtent\"]],[30,1],[30,0,[\"position\",\"center\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[11,0],[24,0,\"d-none\"],[4,[38,2],[[28,[37,3],[[30,0,[\"setupExtent\"]],[30,2]],null]],null],[12],[13],[1,\"\\n  \"],[8,[39,4],null,[[\"@message\"],[[28,[37,5],[\"message-map-marker-point-move\"],null]]],null],[1,\"\\n\\n  \"],[8,[39,6],[[24,0,\"p-3 text-center\"]],[[\"@right\",\"@bottom\",\"@left\"],[true,true,true]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,7],[[24,0,\"btn btn-success btn-save btn-lg\"],[24,4,\"button\"]],[[\"@action\"],[[28,[30,0,[\"handleSave\"]],[[30,2]],null]]],[[\"default\"],[[[[1,[28,[35,5],[\"save\"],null]]],[]]]]],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[\"@center\",\"map\"],false,[\"map-interactive\",\"div\",\"did-insert\",\"fn\",\"map/map-center-marker\",\"t\",\"map/container\",\"input/button\"]]",
    "moduleName": "map/components/map-location-selector.hbs",
    "isStrictMode": false
  });
  let MapLocationSelectorComponent = _exports.default = (_class = class MapLocationSelectorComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "position", _descriptor, this);
    }
    get extent() {
      return new _geoJson.GeoJson(this.args.extent);
    }
    get userInExtent() {
      if (!this.position.hasPosition) {
        return false;
      }
      const extent = this.extent.toOlFeature().getGeometry();
      const point = new _geoJson.GeoJson(this.position.center).toOlFeature().getGeometry().getCoordinates();
      return extent.intersectsCoordinate(point);
    }
    setupExtent(map) {
      if (this.userInExtent) {
        return;
      }
      const extentM = this.extent.toOlFeature().getGeometry().getExtent();
      const extent = (0, _proj.transformExtent)(extentM, 'EPSG:4326', 'EPSG:3857');
      map.getView().fit(extent);
    }
    handleSave(map) {
      const center = (0, _proj.transform)(map.getView().getCenter(), 'EPSG:3857', 'EPSG:4326');
      return this.args.onChange(center);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "position", [_service.service], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setupExtent", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setupExtent"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "handleSave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "handleSave"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MapLocationSelectorComponent);
});